import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import {useTranslation, withTranslation} from "react-i18next";
import i18next from "i18next";
import wechatIcon from "@iconify-icons/mdi/wechat";
import Popup from "reactjs-popup";
import { Icon, InlineIcon } from "@iconify/react";
import 'reactjs-popup/dist/index.css';


class Footer extends Component {

    handleClick(lang) {
        i18next.changeLanguage(lang).then(r => console.log(r) )

    }

    render() {
        // const { t, i18n } = useTranslation();
        const {t} = this.props;

        return (
            // Footer strat
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Link to="index.html" className="logo foo-logo">
                                <img src={ require("../assets/images/logo/logo-full-gold.png") } alt="" />
                            </Link>
                            <nav className="foo-nav">
                                <ul>
                                    <li><Link to="/">{t("Menu.home")}</Link></li>
                                    <li><Link to="/about">{t("Menu.aboutUs")}</Link></li>
                                    <li><Link to="services">{t("Menu.services")}</Link></li>
                                    <li><Link to="promotions">{t("Menu.promotions")}</Link></li>
                                    <li><Link to="/contact">{t("Menu.contactUs")}</Link></li>
                                    <li><Link to="#" onClick={() => this.handleClick('en')}>EN</Link></li>
                                    <li><Link to="#" onClick={() => this.handleClick('chi')}>中文</Link></li>
                                </ul>
                            </nav>
                            <div className="foo-social">
                                <a href="https://www.facebook.com/vcosmeticsydney" target='_blank'><i className="ti-facebook"></i></a>
                                {/*<Link to="#"><i className="ti-twitter-alt"></i></Link>*/}
                                {/*<Link to="#"><i className="ti-vimeo-alt"></i></Link>*/}
                                <a href="https://www.instagram.com/v_cosmetic_official/" target='_blank' > <i className="ti-instagram"></i></a>
                                <Popup trigger={<a> <Icon icon={wechatIcon} /></a>} position="top center">
                                    <div>
                                        <img src= {require("../assets/images/logo/QRcode.png")} height={10} width={10} alt="WeChat"/>
                                    </div>
                                </Popup>
                            </div>
                            <p className="copyright">&copy; COPYRIGHT 2021 V.Cosmetic ALL RIGHTS RESERVED.<br/>
                                <a href="https://mangointelligence.com.au/">Powered by Mango Intelligence.</a> </p>

                        </div>
                    </div>
                </div>
            </footer>
        )
    }

}



export default withTranslation()(Footer);