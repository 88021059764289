import React from 'react'
import {useState, useEffect} from "react";
import firebase from "../firebase";
import {useTranslation} from "react-i18next";


function Contact() {
    const {t} = useTranslation();

    const db = firebase.firestore();
    const [name1, setName1] = useState('');
    const [email1, setEmail1] = useState('');
    const [message1, setMessage1] = useState('');
    const [phone1, setPhone1] = useState('');

    // Save data to firebase under collection ContactUsMessage
    const newRecord = () => {
        let date1 = new Date();
        db.collection('ContactUsMessage').add({
            name: name1,
            phone: phone1,
            email: email1,
            message: message1,
            date: date1
        })
    };


    const handleSubmit = event => {
        event.preventDefault();
        newRecord();
        setName1('');
        setEmail1('');
        setMessage1('');
        setPhone1('')
        alert('Thanks for contacting us, we will get back to you ASAP');
    }

    return (

        // Contact section start
        <section className="contact">
            <metaTags>
                <title>V-Cosmetic Beauty starts from here</title>
                <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                <meta name="keywords" content="V-Cosmetic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
            </metaTags>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-10 m-auto">
                        <div className="sec-heading">
                            <h3 className="sec-title">{t("ContactUs.title")}</h3>
                            <p>{t("ContactUs.content")}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-10 col-md-12 m-auto">
                        <div className="row">
                            <div className="col-md-4">
                                <div>
                                    <span className=""/>
                                    <br/>
                                    <br/>


                                </div>
                                <address>
                                    <span className="ti-map-alt"/>
                                    309/405 Sussex St<br/>
                                    Haymarket NSW 2000

                                </address>
                                {/*<address>*/}
                                {/*    <span className="ti-map-alt"/>*/}
                                {/*    2/17 Macmahon street<br/> Hurstville NSW 2220*/}
                                {/*</address>*/}
                                <address>
                                    <span className="ti-email"/>

                                    <a href="mailto:info@v-cosmetic.com.au">info@v-cosmetic.com.au</a>
                                    <a href="mailto:info@v-cosmetic.com.au">    <br/>            </a>

                                </address>
                                <address>
                                    <span className="ti-tablet"/>
                                    <a href="tel:+61477496666">Tel: 0499 466 668</a>
                                    <a href=""> WeChat: V-cosmeticHV </a>
                                </address>
                            </div>
                            <div className="col-md-7 offset-md-1">
                                <form onSubmit={handleSubmit} className="contact-form" id="contact-form">
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder={t("ContactUs.name")}
                                        value={name1}
                                        onChange={event => setName1(event.target.value)}
                                        required
                                    />
                                    <input

                                        name="phone number"
                                        placeholder={t("ContactUs.phone")}
                                        id="phone"
                                        value={phone1}
                                        onChange={event => setPhone1(event.target.value)}
                                        required

                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder={t("ContactUs.email")}
                                        id="email"
                                        value={email1}
                                        onChange={event => setEmail1(event.target.value)}

                                    />
                                    <textarea name="message"
                                              placeholder={t("ContactUs.comment")}
                                              id="message"
                                              value={message1}
                                              onChange={event => setMessage1(event.target.value)}
                                              required
                                    />
                                    <button type="submit" className="btn btn-round-1">{t("ContactUs.button")}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}









export default Contact;