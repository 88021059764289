import React from 'react'
import Banner from '../../Sections/Banner';
import AboutSec from '../../Sections/About';
import Service from '../../Sections/Service';
import Procedure from '../../Sections/Procedure';
import Testimonial from '../../Sections/Testimonial';
import Promotions from '../../Sections/Promotions';
import Partners from '../../Sections/Partners';
import Contact from '../../Sections/Contact';
import Certificates from "../../Sections/Certificates";

const home = props => {
    return (
        <React.Fragment>
            <Banner />
            <AboutSec />
            <Service />

            <Procedure />
            <Testimonial />
            <Certificates/>
            <Promotions />
            {/*<Appointment />*/}
            {/*<Blog />*/}
            <Partners/>
            <Contact />
        </React.Fragment>
    )
}

export default home