import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

class Service extends Component {

    state= {
        responsive:{
            0: {
                items: 2,
            },
            576: {
                items: 4,
            },
            768: {
                items: 6,
            },
        },
    }

    render() {
         return (
        // Service section start
        <section className="service">
            <metaTags>
                <title>V-Cosmetic Beauty starts from here</title>
                <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                <meta name="keywords" content="V-Cosmetic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
            </metaTags>
            <OwlCarousel
                autoplay
                className="service-caro"
                dots={true}
                items={4}
                loop
                margin={0}
                nav={false}
                responsive={this.state.responsive}
            >
                <div className="single-service">
                    <img src={ require("../assets/images/vc/vc-1.png") } alt="" />
                    <div className="service-hover">
                        {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                        <span>V Cosmetic</span>
                    </div>
                </div>

                <div className="single-service">
                    <img src={ require("../assets/images/vc/vc-2.png") } alt="" />
                    <div className="service-hover">
                        {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                        <span>V Cosmetic</span>
                    </div>
                </div>

                {/*<div className="single-service">*/}
                {/*    <img src={ require("../assets/images/vc/vc-3.png") } alt="" />*/}
                {/*    <div className="service-hover">*/}
                {/*        <img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                {/*        /!*<span>SPA</span>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="single-service">
                    <img src={ require("../assets/images/vc/vc-4.png") } alt="" />
                    <div className="service-hover">
                        {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                        <span>V Cosmetic</span>
                    </div>
                </div>

                <div className="single-service">
                    <img src={ require("../assets/images/vc/vc-5.png") } alt="" />
                    <div className="service-hover">
                        {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                        <span>V Cosmetic</span>
                    </div>
                </div>

                <div className="single-service">
                    <img src={ require("../assets/images/vc/vc-6.png") } alt="" />
                    <div className="service-hover">
                        {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                        <span>V Cosmetic</span>
                    </div>
                </div>
                <div className="single-service">
                    <img src={ require("../assets/images/vc/vc-7.png") } alt="" />
                    <div className="service-hover">
                        {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                        <span>V Cosmetic</span>
                    </div>
                </div><div className="single-service">
                <img src={ require("../assets/images/vc/vc-8.png") } alt="" />
                <div className="service-hover">
                    {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                    <span>V Cosmetic</span>
                </div>
            </div>

            </OwlCarousel>
        </section>
        // Service section end
    )
    }
   
}

export default Service