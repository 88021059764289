import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
// import i18next from "../i18next";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
class Navbar extends Component {

    state = {
        menuOpen: false
    }

    // isHome = 
    // absHeader = ( this.props.location.pathname === "/") ? ' abs-header' : '';
    // right = ( this.props.location.pathname === '/') ? '' : ' text-right';

    menuToggleHandler = () => {
        this.setState( prevState => {
            return { menuOpen: !prevState.menuOpen }
        })
    }

    // componentWillUnmount() {
    //     this.clearStateHandler()
    // }

    clearStateHandler = () => {
        if (this.state.menuOpen) {
            this.setState({
                menuOpen: false
            })
        }
    }
    handleClick(lang) {
        i18next.changeLanguage(lang).then(r => console.log(r) )

    }
    render() {
        // const { t, i18n } = useTranslation();
        const { t } = this.props;
        let absHeader = ( this.props.location.pathname === "/") ? ' abs-header' : '';
        let right = ( this.props.location.pathname === '/') ? '' : ' text-right';

        return (

            // Header start
            <header className={"header" + absHeader }>
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-md-3">
                            <NavLink to="/" className="logo">
                                <img src= { require("../assets/images/logo/logo-4.png")} alt="" />
                            </NavLink>
                        </div>
                        <div className={"col-md-9" + right}>
                            <nav className="primary-menu">
                                <button className="mobile-menu" onClick={ this.menuToggleHandler }><i className="ti-menu"></i></button>
                                <ul className={ this.state.menuOpen ? "active" : '' }> 
                                    <li><NavLink to="/" onClick={ this.clearStateHandler }>{t("Menu.home")}</NavLink></li>
                                    <li><NavLink to="/about" onClick={ this.clearStateHandler }>{t("Menu.aboutUs")}</NavLink></li>

                                    <li><NavLink to="/services" onClick={ this.clearStateHandler }>{t("Menu.services")}</NavLink></li>
                                    <li><NavLink to="/promotions" onClick={ this.clearStateHandler }>{t("Menu.promotions")}</NavLink></li>
                                    {/*<li><NavLink to="/blog" onClick={ this.clearStateHandler }>blog</NavLink></li>*/}
                                    <li><NavLink to="/contact" onClick={ this.clearStateHandler }>{t("Menu.contactUs")}</NavLink></li>
                                    <li><Link  to="#" onClick={() => this.handleClick('en')}>en</Link></li>
                                    <li><Link  to="#" onClick={() => this.handleClick('chi')}>中文</Link></li>
                                </ul>
                                {/*<button onClick={() => this.handleClick('en')}>eng</button>*/}
                                {/*<button onClick={() => this.handleClick('chi')}>中文</button>*/}
                            </nav>

                        </div>
                    </div>
                </div>
            </header>
            // Header start
        )
    }
    
}

// export default withRouter(Navbar)
export default withTranslation()(withRouter(Navbar));