import React from 'react'

const singleTestimonial = props => {
    return (
        <div className="testimonial single-tst">
            <img src={ require("../assets/images/quote-1.png") } alt="" />
            <p>{props.body}</p>
            <div className="client-info">
                {/*<img src={ require("../assets/images/client-tst.png") } alt="" className="thumb" />*/}
                <p>{props.name}, <span>{props.designation}</span></p>
            </div>
        </div>
    )
}

export default singleTestimonial