import React, { Component } from 'react'
import PromotionLanding from '../Components/PromotionLanding'
import {withTranslation} from "react-i18next";


class Promotions extends Component {

    state = {
        category: [{"id": 0,}, {"id": 1,}, {"id": 2,}]

    }


    render() {
        const { t } = this.props;

        const cat = this.state.category.map( cat => {
            return (
                <div className="col-md-4" key={ cat.id }>
                    <PromotionLanding
                        id={ cat.id }
                        name={t("category." +cat.id +".name")}
                        speciality={t("category." +cat.id +".specialist")}
                    />
                </div>
                
            )
        })

        return (
            // Team section start
            <section className="team">
                <metaTags>
                    <title>V-Cosmetic Beauty starts from here</title>
                    <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                    <meta name="keywords" content="V-Cosmetic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
                </metaTags>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-10 m-auto">
                            <div className="sec-heading">
                                <h3 className="sec-title">{t("promotions.title")}</h3>
                                <p>{t("promotions.content")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        { cat }
                    </div>
                </div>
            </section>
            // Team section end
        )
    }
    
}

export default withTranslation()(Promotions)