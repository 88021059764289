import React, { Component } from 'react'
import SingleMember from '../../Components/SinglePromotion'
import Modal from "react-modal";
import {useState} from "react";
import styled from "styled-components";
import {MdClose} from "react-icons/md";
import {withTranslation} from "react-i18next";

class Promotions extends Component {

    state = {
        category: [{"id": 0,}, {"id": 1,}, {"id": 2,},{"id":3},
            {"id":4}, {"id":5}]
    }


    render() {
        const { t } = this.props;

        const CloseModalButton = styled(MdClose)`
          cursor: pointer;
          position: absolute;
          top: 20px;
          right: 20px;
          width: 32px;
          height: 32px;
          padding: 0;
          z-index: 10;
        `;
        const cat = this.state.category.map( cat => {
            return (
                <div className="col-md-4" key={ cat.id }>
                    <SingleMember
                        id={ cat.id }
                        name={t("category." +cat.id +".name")}
                        speciality={t("category." +cat.id +".specialist")}
                        link={t("category." +cat.id +".link")}
                        detail={t("category." +cat.id +".details")}
                        qa={t("category." +cat.id +".qa")}
                        nav1={t("category." +cat.id +".nav1")}
                        nav2={t("category." +cat.id +".nav2")}
                        nav={t("category." +cat.id +".nav")}

                    />
                </div>

            )
        })

        return (
            // Team section start
            <section className="team">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-10 m-auto">
                            <div className="sec-heading">
                                <h3 className="sec-title">{t("promotions.title")}</h3>
                                <p>{t("promotions.content")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row promotion-container-out">
                        { cat }
                    </div>
                </div>
            </section>
            // Team section end
        )
    }

}

export default withTranslation()(Promotions)