import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";



const PromotionsLanding = props => {
    const {t} = useTranslation();

    return(
        <div className="promotion-container">
            <div className="single-memb">


                <img src={ require("../assets/images/promotions/" + props.id + ".png") } alt="" />

                <div className="memb-details">
                    <h6 >{ props.name }</h6>
                    <span>{ props.speciality }</span>
                    <Link to="/promotions" className="btn btn-round-1">{t("promotions.button")}</Link>

                </div>


            </div>


        </div>
    )




}

export default PromotionsLanding