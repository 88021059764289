import React from 'react'
import {Link} from 'react-router-dom'
import Modal from "react-modal";
import {useState} from "react";
import styled from "styled-components";
import {MdClose} from "react-icons/md";
import {useTranslation} from "react-i18next";

Modal.setAppElement('#root')

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;


const Post = props => {
    const {t} = useTranslation();
    // console.log('render is working');
    const [showModal, setShowModal] = useState(false);
    // const [showPost, setShowPost] = useState(true);
    // const selector = querySelector
    const openModal = () => {
        setShowModal(prev => !prev);
    }

    return (

        <div className="col-md-4">
            <article className="post">
                <img src={require("../assets/images/post/" + props.imgId + ".png")} alt=""/>
                {/*<h4><Link to={'/blog/'+props.id}>{props.title}</Link></h4>*/}
                <h3>{props.title}</h3>
                <hr/>
                {/*<h4>{props.title}</h4>*/}
                <p>{props.excerpt}</p>
                {/*<hr className="br-1"/>*/}

                <button onClick={openModal} className="btn btn-round-1">{t("Services.button")}</button>
                {/*<br className="br-1"/>*/}

            </article>

            {/*<button onClick={openModal} className="btn btn-round-1">{t("Services.button")}</button>*/}


            <Modal isOpen={showModal}
                   onRequestClose={openModal}
                   style={
                       {
                           overlay: {
                               position: 'fixed',
                               top: '10%',
                               left: '12%',
                               right: '12%',
                               bottom: '30%',
                               backgroundColor: 'sliver'
                           },
                           content: {
                               position: 'absolute',
                               top: '10%',
                               left: '5%',
                               right: '5%',
                               bottom: '25%',
                               border: '1px solid #ccc',
                               // background: '#fff',
                               background: '#fff',
                               overflow: 'auto',
                               WebkitOverflowScrolling: 'touch',
                               borderRadius: '20px',
                               outline: '20%',
                               padding: '20px'
                           },
                           image: {
                               position: 'center',
                               top: '40px',
                               left: '40px',
                               right: '40px',
                               bottom: '40px',
                               border: '1px solid #ccc',
                           }

                       }
                   }
            >
                <article className="post1">
                    <img src={require("../assets/images/post/" + props.imgId + ".png")} alt=""/>
                    {/*<h4><Link to={'/blog/'+props. id}>{props.title}</Link></h4>*/}
                    <h3>{props.title}</h3>
                    <br/>
                    {props.excerpt}
                    <hr/>
                    <br/>
                    {props.body}
                    <br/>
                    <hr/>
                    {props.body2}
                    <CloseModalButton
                        aria-label='Close modal'
                        onClick={() => setShowModal(prev => !prev)}
                    />
                </article>

            </Modal>
        </div>


    )
}

export default Post