import React from 'react'
import { Link } from 'react-router-dom'
import Modal from "react-modal";
import {useState} from "react";
import styled from "styled-components";
import {MdClose} from "react-icons/md";
import {useTranslation} from "react-i18next";
import { BsFillStarFill } from "react-icons/bs";


Modal.setAppElement('#root')

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;


const PartnersLanding = props => {
    const {t} = useTranslation();
    // console.log('render is working');

    // const [showPost, setShowPost] = useState(true);
    // const selector = querySelector


    return (

        <div className="col-sm-2">
            <article className="partner">
                <img src={ require("../assets/images/post/" + props.imgId + ".png") } alt="" />
            </article>


        </div>




    )
}

export default PartnersLanding