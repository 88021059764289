import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'


const layout = props => {
    return (
        <React.Fragment>
            <metaTags>
                <title>V-Cosmetic Beauty starts from here</title>
                <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                <meta name="keywords" content="V-Cosmetic, V Cosmetic, clinic, best clinic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
            </metaTags>
            <Navbar />
            { props.children }
            <Footer />
        </React.Fragment>
    )
}

export default layout