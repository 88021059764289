import React, { Component } from 'react'
import axios from '../axios-orders'
import Post from '../Components/Post'
import { withTranslation } from 'react-i18next';
import PostLanding from "../Components/PostLanding";

class Procedure extends Component {

    state = {
        procedures: [{"id": 0,}, {"id": 1,}, {"id": 2,},]
    }
    render() {

        const { t } = this.props;

        let procedures = "Loading..."
        if( this.state.procedures !== null ) {
            procedures = this.state.procedures.map( procedure => {
                // let str1 = String(procedure.id) ;
                // let str2 = "t(\"TopServices.!";
                // let res = str1.concat(str2);
                // console.log("concat:", res)
                return (
                    <PostLanding
                        key={procedure.id}
                        id={procedure.id}
                        imgId={t("TopServices." +procedure.id +".imgId")}
                        title={t("TopServices." +procedure.id +".title")}
                        excerpt={t("TopServices." +procedure.id +".subtitle")}
                        body={t("TopServices." +procedure.id +".body")}
                        body2={t("TopServices." +procedure.id +".body2")}
                        // url={'/services'}
                    />
                )            
            })
        }
        
        
        return (

            // Procedures section start
            <section className="procedures">
                <metaTags>
                    <title>V-Cosmetic Beauty starts from here</title>
                    <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                    <meta name="keywords" content="V-Cosmetic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
                </metaTags>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-10 m-auto">
                            <div className="sec-heading">
                                <h3 className="sec-title">{t("Services.title2")}</h3>
                                <p>{t("Services.content")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        { procedures }
                    </div>
                </div>      
            </section>
            // Procedures section end
        )
    }
}

export default withTranslation()(Procedure)