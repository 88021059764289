import React from 'react'
import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next";


const Banner = props => {
    const {t} = useTranslation();


    return (
        // Banner section start
        <section className="banner">
            <metaTags>
                <title>V-Cosmetic Beauty starts from here</title>
                <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                <meta name="keywords" content="V-Cosmetic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
            </metaTags>
            <div className="spa-img">
                <img src={ require("../assets/images/spa-right.png") } alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <span> </span>
                            <br/>
                            <span> </span>
                            <h2>{t("Banner.title")}</h2>
                            <p>{t("Banner.content")}</p>
                            <Link to="/services" className="btn btn-round-1">{t("Banner.button")}</Link>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        // Banner section end 
    )
}

export default Banner