import React, { Component } from 'react'
import axios from '../axios-orders';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import SingleTestimonial from '../Components/SingleTestimonial';
import { withTranslation } from 'react-i18next';
class Testimonial extends Component {

    state = {
        testimonials: [{"id": 0,}, {"id": 1,}, {"id": 2,}, {"id": 3,}, {"id": 4,}, {"id": 5,}, {"id": 6,}, {"id": 7,},
            {"id": 8,}, {"id": 9,}]
    }

    render() {
        const { t } = this.props;

        let testimonials = 'Loading...'
        if( this.state.testimonials.length) {
            testimonials = <OwlCarousel
                autoplay
                className="test-caro"
                dots={true}
                items={1}
                loop
                margin={0}
                nav={false}
            > 
            {
                this.state.testimonials.map( data => {
                    return (
                        <SingleTestimonial 
                            key={data.id}
                            id={t("testimonials." +data.id +".userId")}
                            name={t("testimonials." +data.id +".name")}
                            designation={t("testimonials." +data.id +".designation")}
                            body={t("testimonials." +data.id +".body")}
                        />
                    )
                })
            }
            </OwlCarousel>
        }
        

        return (
            // Testimonial section start 
            <section className="testimonial bg-lightred">
                <metaTags>
                    <title>V-Cosmetic Beauty starts from here</title>
                    <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                    <meta name="keywords" content="V-Cosmetic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
                </metaTags>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-md-12 m-auto">
                            {testimonials}
                        </div>
                    </div>
                </div>
            </section>
            // Testimonial section end
        )
    }
}

export default withTranslation()(Testimonial)