import React, {useState} from 'react'

import Modal from "react-modal";
import styled from "styled-components";
import {MdClose} from "react-icons/md";
import {useTranslation} from "react-i18next";


Modal.setAppElement('#root')

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;



const Promotions = props => {
    const {t} = useTranslation();
    // console.log('props',props);
    const [showModal, setShowModal] = useState(false);
    // const [showPost, setShowPost] = useState(true);
    // const selector = querySelector
    const openModal = () => {
        setShowModal(prev => !prev);
        for (let el of document.querySelectorAll('.memb-details')) el.style.visibility = 'hidden';
    }
    const closeModal = () => {
        setShowModal(prev => !prev);
        for (let el of document.querySelectorAll('.memb-details')) el.style.visibility = 'visible';
    }

    // let selectId

    if (! showModal) {
        for (let el of document.querySelectorAll('.memb-details')) el.style.visibility = 'visible';
        // console.log('I am in if');
        return (
            <div className="promotion-container">
                <div className="single-memb">


                    <img src={ require("../assets/images/promotions/" + props.id + ".png") } alt="" />

                    <div className="memb-details">
                        <h6 >{ props.name }</h6>
                        <span>{ props.speciality }</span>

                        <button onClick={openModal} className="btn btn-round-1">{t("promotions.button")}</button>
                        {/*<MemberSocial socialLinks={ props.social } />*/}


                    </div>


                </div>


                {/*<Modal isOpen={showModal}*/}

                {/*       style={*/}
                {/*           {*/}
                {/*               overlay: {*/}
                {/*                   position: 'fixed',*/}
                {/*                   top: '20%',*/}
                {/*                   left: '20%',*/}
                {/*                   right: '20%',*/}
                {/*                   bottom: '20%',*/}
                {/*                   backgroundColor: 'sliver'*/}
                {/*               },*/}
                {/*               content: {*/}
                {/*                   position: 'absolute',*/}
                {/*                   top: '40px',*/}
                {/*                   left: '50px',*/}
                {/*                   right: '50px',*/}
                {/*                   bottom: '40px',*/}
                {/*                   border: '1px solid #ccc',*/}
                {/*                   background: '#fff',*/}
                {/*                   overflow: 'auto',*/}
                {/*                   WebkitOverflowScrolling: 'touch',*/}
                {/*                   borderRadius: '20px',*/}
                {/*                   outline: '50px',*/}
                {/*                   padding: '20px'*/}
                {/*               },*/}
                {/*               image: {*/}
                {/*                   position: 'center',*/}
                {/*                   top: '40px',*/}
                {/*                   left: '40px',*/}
                {/*                   right: '40px',*/}
                {/*                   bottom: '40px',*/}
                {/*                   border: '1px solid #ccc',*/}
                {/*               }*/}

                {/*           }*/}
                {/*       }*/}
                {/*>*/}
                {/*    <div className="memb-details">*/}
                {/*        /!*<img src={ require("../assets/images/promotions/" +props.id+ ".png") } alt="" />*!/*/}
                {/*        <h3>{props.id}</h3>*/}
                {/*        <h3>{props.name}</h3>*/}
                {/*        <p>{props.speciality}</p>*/}
                {/*        <p>Please click <a href={props.link}>HERE</a> to check out </p>*/}
                {/*        <p>{props.details}</p>*/}
                {/*        <p>{props.qa}</p>*/}
                {/*        <CloseModalButton*/}
                {/*            aria-label='Close modal'*/}
                {/*            onClick={closeModal}*/}
                {/*        />*/}
                {/*    </div>*/}

                {/*</Modal>*/}


            </div>

        )


    } else {

        // const accessibility_elements = document.querySelectorAll(".memb-details");
        // console.log('I am in accessibility_elements',accessibility_elements);

        // for (let el of document.querySelectorAll('.memb-details')) el.style.visibility = 'hidden';
        return (
            <div>


                <Modal isOpen={showModal}
                       onRequestClose={openModal}
                       style={
                           {
                               overlay: {
                                   position: 'flex',

                                   top: '40px',
                                   left: '40px',
                                   right: '40px',
                                   bottom: '40px',
                                   backgroundColor: 'sliver'
                               },
                               content: {

                                   position: 'absolute',
                                   top: '20%',

                                   left: '20px ',
                                   right: '20px  ',
                                   bottom: '30%',
                                   border: '1px solid #ccc',
                                   background: '#fff',
                                   overflow: 'auto',
                                   WebkitOverflowScrolling: 'touch',
                                   borderRadius: '20px',
                                   outline: '20%',
                                   padding: '10%'
                               },
                               image: {

                                   // position: 'flex',
                                   top: '80% auto',
                                   left: '80%',
                                   borderLeft:"30px",
                                   borderRadius:"50px",
                                   right: '10%',
                                   bottom: '50%',
                                   border: '1px solid #ccc',
                               }

                           }
                       }
                >
                    <div className="#team #single-memb #memb-details">
                        {/*<img src={ require("../assets/images/promotions/" + props.id + ".png") } alt=""  />*/}
                        {/*<h3>{props.id}</h3>*/}
                        <h2>{props.name}</h2>
                        {/*<image ref={ require("../assets/images/promotions/" + props.id + ".png")}/>*/}
                        <h5>{props.speciality}</h5>

                        <p>{props.details}</p>
                        <p>{props.qa}</p>
                        <p>{props.nav1} <a href={props.link} target='_blank'>{props.nav}</a> {props.nav2}</p>
                        {/*<p>{props.link}</p>*/}
                        <CloseModalButton
                            aria-label='Close modal'
                            onClick={closeModal}
                        />
                    </div>

                </Modal>


            </div>

        )
    }



}

export default Promotions