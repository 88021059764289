import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Translation } from 'react-i18next';

class aboutSec extends Component {




    render() {
        const { t } = this.props;

        return(
            // <Translation>
            // About section start
            <section className="about">
                <metaTags>
                    <title>V-Cosmetic Beauty starts from here</title>
                    <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                    <meta name="keywords" content="V-Cosmetic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
                </metaTags>
                {/*<img src={ require("../assets/images/china-rose.png") } alt="" className="flower-1" />*/}
                {/*<img src={ require("../assets/images/jasmine.png") } alt="" className="flower-2" />*/}

                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-md-12 m-auto">
                            <div className="sec-heading">
                                <img src={ require("../assets/images/logo/logo-gold.png") } alt="" />
                                {/*<span className="tagline">About V Cosmetic</span>*/}
                                <h3 className="sec-title">{t("AboutUs.title")}</h3>

                                <p>{t("AboutUs.content")}</p>
                                </div>
                            <Link to="/contact" className="btn btn-round-1">{t("AboutUs.button")}</Link>
                        </div>
                    </div>
                </div>
            </section>
            // About section end
            // </Translation>

        )
    }

}


export default withTranslation()(aboutSec);