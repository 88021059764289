import React, { Component } from 'react'
// import axios from '../axios-orders'
import Post from '../../Components/Post'
import {withTranslation} from "react-i18next";


class AllServices extends Component {

    state = {
        procedures: [{"id": 0,}, {"id": 1,}, {"id": 2,},{"id": 3,}, {"id": 4,}, {"id": 5,}, {"id": 6,}, {"id": 7,}, {"id": 8,}]
    }

    // componentDidMount() {
    //     axios.get('/procedures.json')
    //         .then( res => {
    //             // console.log(res)
    //             this.setState({ procedures: res.data.slice(0, 3) })
    //         })
    //         .catch( err => {
    //             console.log(err)
    //         })
    // }

    render() {
        const { t } = this.props;

        // URL should be single service page -> '/services/'+procedure.id

        let procedures = "Loading..."
        if( this.state.procedures !== null ) {
            procedures = this.state.procedures.map( procedure => {
                return (
                    <Post
                        key={procedure.id}
                        id={procedure.id}
                        imgId={t("TopServices." +procedure.id +".imgId")}
                        title={t("TopServices." +procedure.id +".title")}
                        excerpt={t("TopServices." +procedure.id +".subtitle")}
                        body={t("TopServices." +procedure.id +".body")}
                        body2={t("TopServices." +procedure.id +".body2")}
                        // url={'/'}
                    />
                )
            })
        }


        return (

            // Procedures section start
            <section className="procedures">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-10 m-auto">
                            <div className="sec-heading">
                                <h3 className="sec-title">{t("Services.title")}</h3>
                                <p>{t("Services.content")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        { procedures }
                    </div>
                </div>
            </section>
            // Procedures section end
        )
    }
}

export default withTranslation()(AllServices)