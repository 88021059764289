import React from 'react'
import { Link } from 'react-router-dom'
import Modal from "react-modal";
import {useState} from "react";
import styled from "styled-components";
import {MdClose} from "react-icons/md";
import {useTranslation} from "react-i18next";
import { BsFillStarFill } from "react-icons/bs";


Modal.setAppElement('#root')

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;


const PostLanding = props => {
    const {t} = useTranslation();
    // console.log('render is working');

    // const [showPost, setShowPost] = useState(true);
    // const selector = querySelector


    return (

        <div className="col-md-4">
            <article className="post">
                <img src={ require("../assets/images/post/" + props.imgId + ".png") } alt="" />
                {/*<h4><Link to={'/blog/'+props.id}>{props.title}</Link></h4>*/}
                <h3>{props.title}</h3>
              <BsFillStarFill color="gold" size="1.5em"/> <BsFillStarFill color="gold" size="1.5em"/> <BsFillStarFill color="gold" size="1.5em"/> <BsFillStarFill color="gold" size="1.5em"/> <BsFillStarFill color="gold" size="1.5em"/>
                <hr/>
                {/*<h4>{props.title}</h4>*/}
                <p className="p-1">{props.excerpt}</p>

                <Link to="/services" className="btn btn-round-1">{t("Services.button")}</Link>
            </article>


        </div>




    )
}

export default PostLanding