import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

class Service extends Component {

    state= {
        responsive:{
            0: {
                items: 2,
            },
            576: {
                items: 4,
            },
            768: {
                items: 6,
            },
        },
    }

    render() {
        return (
            // Service section start
            <section className="service">

                <OwlCarousel
                    autoplay
                    className="service-caro"
                    dots={true}
                    items={6}
                    loop
                    margin={0}
                    nav={false}
                    responsive={this.state.responsive}
                >
                    <div className="single-service">
                        <img src={ require("../assets/images/certificates/1.png") } alt="" />
                        <div className="service-hover">
                            {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                            <span>V Cosmetic</span>
                        </div>
                    </div>
                    <div className="single-service">
                        <img src={ require("../assets/images/certificates/2.png") } alt="" />
                        <div className="service-hover">
                            {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                            <span>V Cosmetic</span>
                        </div>
                    </div>
                    <div className="single-service">
                        <img src={ require("../assets/images/certificates/3.png") } alt="" />
                        <div className="service-hover">
                            {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                            <span>V Cosmetic</span>
                        </div>
                    </div>
                    <div className="single-service">
                        <img src={ require("../assets/images/certificates/4.png") } alt="" />
                        <div className="service-hover">
                            {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                            <span>V Cosmetic</span>
                        </div>
                    </div>
                    <div className="single-service">
                        <img src={ require("../assets/images/certificates/5.png") } alt="" />
                        <div className="service-hover">
                            {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                            <span>V Cosmetic</span>
                        </div>
                    </div>
                    <div className="single-service">
                        <img src={ require("../assets/images/certificates/6.png") } alt="" />
                        <div className="service-hover">
                            {/*<img src={ require("../assets/images/icons/1.png") } alt="" />*/}
                            <span>V Cosmetic</span>
                        </div>
                    </div>




                </OwlCarousel>
            </section>
            // Service section end
        )
    }

}

export default Service