import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {withTranslation} from 'react-i18next';
import {Translation} from 'react-i18next';
import PostLanding from "../Components/PostLanding";
import PartnersLanding from "../Components/PartnersLanding";

class Partners extends Component {


    state = {
        procedures: [{"id": 0,}, {"id": 1,}, {"id": 2,}, {"id": 3,}, {"id": 5,}, {"id": 6,}, {"id": 7,},
            {"id": 9,}, {"id": 10,}, {"id": 11,}, {"id": 12,}, {"id": 13,}, {"id": 14,}, {"id": 15,}
            , {"id": 16,}, {"id": 17,}, {"id": 18}, {"id": 19}]
    }

    render() {
        const {t} = this.props;

        let procedures = "Loading..."
        if (this.state.procedures !== null) {
            procedures = this.state.procedures.map(procedure => {
                return (
                    <PartnersLanding
                        key={procedure.id}
                        id={procedure.id}
                        imgId={t("TopServices." + procedure.id + ".imgId")}
                    />
                )
            })
        }

        return (
            // <Translation>
            // About section start
            <section className="about">
                <metaTags>
                    <title>V-Cosmetic Beauty starts from here</title>
                    <meta name="description" content="V-Cosmetic Beauty starts from here 美丽从这里开始" />
                    <meta name="keywords" content="V-Cosmetic, babyfacegroup, beauty, facial, Thermage, treatment, Emcyte Pure PRP, Venus Legacy, Venus Glow, Tesla Chair, Tesla Former, Morpheus8,Teosyal RHA, Plastic Surgery,
                 beautifying treatments, skin, sydney, chinatown, Haymarket, 维纳斯女神机, 爱神丘比特, 整形手术, 美丽， 女性之美， 成功女性， 美丽气质， 专业医美 " />
                </metaTags>
                {/*<img src={ require("../assets/images/china-rose.png") } alt="" className="flower-1" />*/}
                {/*<img src={ require("../assets/images/jasmine.png") } alt="" className="flower-2" />*/}

                <div className="container">

                    <div className="partner">
                        <h1 className=" heading">{t("partners.title")}</h1>
                    </div>


                        <div className="row">
                            {procedures}
                        </div>
                </div>
            </section>
            // About section end
            // </Translation>

        )
    }

}


export default withTranslation()(Partners);