import firebase from "firebase";


const Config = {
    apiKey: "AIzaSyA57_3lCiolDzlGKgTygSo8eHJisxn8QQc",
    authDomain: "vcosmetic-15cdb.firebaseapp.com",
    projectId: "vcosmetic-15cdb",
    storageBucket: "vcosmetic-15cdb.appspot.com",
    messagingSenderId: "454598454252",
    appId: "1:454598454252:web:75b6690a24ebcd34322ec9",
    measurementId: "G-GQ0L07FV7K"
};
// Initialize Firebase
firebase.initializeApp(Config);

export default firebase;